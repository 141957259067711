<template>
    <div class="main-content">
        <div class="main-header">
            <div class="header-input-group">
                <div class="theory-libaray theory-input-item">
                    <span class="title-txt">题库分类：</span>
                    <el-cascader v-model="theoryTopicLibed"
                                 :options="theoryTopicLibrary"
                                 :props="props"
                                 @change="handleTheoryTopicLib"
                    ></el-cascader>
                </div>
                <div class="topic-type theory-input-item">
                    <span class="title-txt">题型：</span>
                    <el-select v-model="topicTyped" @change="selectedType">
                        <el-option :value="type.type_id" :label="type.type_name" v-for="(type, key) in topicType" :key="key"></el-option>
                    </el-select>
                </div>
                <div class="topic-search theory-input-item">
                    <el-input v-model="topicTitle" placeholder="请输入关键词" clearable @keydown.enter.native="theoryList">
                        <i slot="suffix" class="el-input__icon el-icon-search" @click="theoryList"></i>
                    </el-input>
                </div>
            </div>
            <div class="header-btns-group">
                <el-button class="btn-blue" @click="dialogVisible = true">导入试题</el-button>
                <el-button class="btn-blue" @click="toTopicDetail">添加试题</el-button>
            </div>
        </div>
        <div class="main-body">
            <el-scrollbar style="height: 100%">
                <el-table :data="tableData" header-cell-class-name="table-header-deep" header-row-class-name="table-header-deep">
                    <el-table-column label="序号" width="100" align="center">
                        <template slot-scope="scope">
                            <span>{{formatIndex(scope.$index)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="题文">
                        <template slot-scope="scope">
                            <el-popover  placement="top" width="400" trigger="hover">
                                <span class="topic-detail" v-html="scope.row.title"></span>
                                <div class="topic-title" slot="reference">
                                    <span v-html="scope.row.title"></span>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="category_name" label="题库分类" width="200" align="center"></el-table-column>
                    <el-table-column prop="type_name" label="题型" width="120"></el-table-column>
                    <el-table-column label="操作" width="200" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="deep-blue" @click="editTopicOne(scope.row)">编辑</el-button>
                            <el-button type="text" class="light-red" @click="delTopicOne(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-scrollbar>
        </div>
        <div class="main-footer">
            <el-pagination
                layout="prev, pager, next, jumper"
                :current-page.sync="page"
                @current-change="handleCurrentChange"
                :total="total">
            </el-pagination>
        </div>
        <div class="import-dialog">
            <el-dialog class="deep-color-dialog" :visible.sync="dialogVisible" width="20%" title="导入试题模板" center>
                <div class="topic-template-panel">
                    <div class="item">
                        <a :href="tmplDownloadUrl" target="_blank"><span>下载试题模板</span></a>
                    </div>
                    <div class="item">
                        <input type="file" name="theory_topic_excel" id="topicTmpl" class="topic-tmpl-input" @change="insertFileName">
                        <el-button type="primary" class="btn-blue" @click="uploadTmpl">选择文件</el-button>
                        <div class="file-name-tag" v-if="fileName"><el-tag closable @close="handleClose">{{fileName}}</el-tag></div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" class="btn-blue" @click="confirmUploadTmpl">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>

</template>

<script>
import {theoryTopicList, theoryLibSelect, theoryTopicDel, downloadTopicTmpl, theoryTopicExcel} from "@/utils/apis";

export default {
    name: "theoryManage",
    data() {
        return {
            theoryTopicLibrary: [{id: 0, name: '全部'}],
            topicTitle: '',
            topicType: [],
            theoryTopicLibed: [0],
            topicTyped: 0,
            page: 1,
            total: 0,
            pageSize: 10,
            props: {
                value: 'id',
                label: 'name'
            },
            tableData: [],
            dialogVisible: false,
            tmplDownloadUrl: '',
            fileName: '',
            fileInstance: null
        }
    },
    created() {
        this.theoryLibList();
        this.theoryList();
        this.fileInstance = document.getElementById('topicTmpl');
        downloadTopicTmpl().then(res => {
            if (res.code === 200) {
                this.tmplDownloadUrl = res.data.url;
            } else {
                this.$message.warning(res.msg)
            }
        }).catch(err => {
            this.$message.warning('试题模板暂不支持下载')
        })
    },
    methods: {
        toTopicDetail() {
            this.$router.push('/admin/topic/detail');
        },
        uploadTmpl() {
            let ele = document.getElementById('topicTmpl').click();
        },
        resetDialog() {
            document.getElementById('topicTmpl').value = "";
        },
        insertFileName() {
            this.fileInstance = document.getElementById('topicTmpl');
            this.fileName = this.fileInstance.files[0].name;
        },
        handleClose() {
            this.fileInstance.value = "";
            this.fileName = "";
        },
        confirmUploadTmpl() {
            let element = document.getElementById('topicTmpl');
            let file = document.getElementById('topicTmpl').files[0];
            if (!file) {
                this.$message.warning('请上传文件');
                return;
            }
            let formdata = new FormData();
            formdata.append('theory_topic_excel', file);
            theoryTopicExcel(formdata).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose: () => {
                            this.theoryList();
                            this.dialogVisible = false;
                            element.value = "";
                            this.fileName = "";
                            this.resetDialog();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                    element.value = "";
                    this.fileInstance.value = "";
                    this.fileName = "";
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        fileValueReset() {
            this.fileName = "";
            this.fileInstance.value = "";
        },
        formatIndex(index) {
            return index + (this.page - 1) * this.pageSize + 1;
        },
        selectedType() {
            this.theoryList();
        },
        handleTheoryTopicLib(val) {
            this.theoryList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.theoryList();
        },
        theoryLibList() {
            let param = {
                paging: 0
            };
            theoryLibSelect().then(res => {
                if (res.code === 200) {
                    this.theoryTopicLibrary = this.theoryTopicLibrary.concat(res.data);
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        theoryList() {
            let param = {
                paging: 1,
                page: this.page,
                pageSize: this.pageSize
            }
            if (this.topicTyped) {
                param.type_id = this.topicTyped;
            }
            if (this.theoryTopicLibed[this.theoryTopicLibed.length - 1]) {
                param.category_id = this.theoryTopicLibed[this.theoryTopicLibed.length - 1];
            }
            if (this.topicTitle) {
                param.like = this.topicTitle.trim();
            }
            theoryTopicList(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.list;
                    this.topicType = [{type_id: 0, type_name: '全部'}].concat(res.data.question_type);
                    this.total = res.data.total;
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        editTopicOne(row) {
            this.$router.push({path: '/admin/topic/detail', query: {id: row.theory_topic_id}});
        },
        delTopicOne(row) {
            this.$confirm('删除该试题之后则无法恢复，确定继续操作？', '删除试题', {
                confirmButtonClass: 'deep-blue-confirm',
            }).then(() => {
                let formdata = {
                    theory_topic_id: row.theory_topic_id
                };
                theoryTopicDel(formdata).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose: () => {
                                this.theoryList();
                            }
                        });
                    } else {
                        this.$message.warning(res.msg);
                    }
                }).catch(err => {
                    console.log('err', err);
                })
            }).catch(err => {
                console.log('err', err);
            })
        }
    }

}
</script>

<style scoped lang="scss">
.main-content {
    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-input-group {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .theory-input-item {
                margin-right: 10px;
            }
            .title-txt {
                margin-right: 10px;
            }
        }
    }
    .main-body {
        width: 100%;
        height: calc(100% - 80px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .main-footer {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.main-body {
    &::v-deep .el-table {
        margin-top: 14px;
        .table-header-deep {
            background-color: rgb(242, 243, 250);
        }
    }
    .topic-title {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .deep-blue {
        &::v-deep.el-button--text {
            color: #1122D8;
            font-size: 14px;
        }
    }
    .light-red {
        &::v-deep.el-button--text {
            color: #EB6547;
            font-size: 14px;
        }
    }
    .el-message-box__btns {
        .deep-blue-confirm {
            &::v-deep.el-button--primary {
                background-color: #000;
            }
        }
    }
}
.import-dialog {
    .item {
        margin-bottom: 10px;
    }
    .topic-tmpl-input {
        display: none;
    }
    .file-name-tag {
        margin-top: 10px;
    }
}


</style>